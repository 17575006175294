import { useEffect, useState } from "react";
import ProfileMenu from "../../../components/ProfileMenu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { balanceAtom } from "../../../App";
import { useAtom } from "jotai";
export default function Wallets () {
    const [t,i18n] = useTranslation();
    const [balance,setBalance] = useAtom(balanceAtom)
const[ walletBalance, setWalletBalance]  =  useState('-');
const[ depositSum, setDepositSum]  =  useState('-');
const[ withdrawalSum, setWithdrawalSum]  =  useState('-');
const[ earnedReferral, setEarnedReferral]  =  useState('-');


const [wager,setWager] = useState(null);
const[isWager,setIsWager] = useState(false);
const [dataLoaded,setDataLoaded] = useState(false)
useEffect(()=>{
    if (dataLoaded)return;
setDataLoaded(true);
axios.get(process.env.REACT_APP_API + "/user/voyager", {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
  },
})
.then((response) => {
setIsWager(response.data.totalLoss >= response.data.voyager);
setWager(formatNumber(response.data.totalLoss) + '/' + formatNumber(response.data.voyager) || null);
}).catch((error)=>{
  console.log(error);
});

axios
      .get(process.env.REACT_APP_API + "/user/balance", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
      setWalletBalance(response.data.balance);
setDepositSum(response.data.totalIncoming);
setWithdrawalSum(response.data.totalWithdrawal);
setEarnedReferral(response.data.totalCashback);
    }).catch((error)=>{

      });
},[]);
function formatNumber(number){
    return number.toLocaleString('en-US');
}
    return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/wallet/wallets'}/>
            <div className="Profile-Block__container">
            <div className="Mobile-Selector">
                <Link to='/profile/wallet/wallets' className="selected">{t('your_wallets')}</Link>
                <Link to='/profile/wallet/deposit' >{t('deposit')}</Link>
           
                <Link to='/profile/wallet/withdraw' >{t('withdraw')}</Link>
                <Link to='/profile/wallet/history'  >История</Link>
            </div>
                <span> <p>{t('wallet')}</p>  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663" stroke="white" stroke-opacity="0.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <p>{t('all_wallets')}</p>
    
    </span>
    <h1>{t('wallet')}</h1>
    <p>{t('wallet_desc')}</p>
            <div className="Profile-Block__fields">
         <nav>
            <p>{t('choose_main_wallet')}</p>
            <div className="selector white-selector  ">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.target.parentElement.classList.toggle("open");
                  }}
                  className="selector__header"
                >
                  <p className="selector__value">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#CC3332" />
                      <path
                        d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                        fill="white"
                      />
                    </svg>

                    {balance.RUB.toFixed(2).toLocaleString("en-US")}
                  </p>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.25 6.375L8.5 10.625L12.75 6.375"
                      stroke="white"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div
                  onClick={(e) => {
                    if (e.target.nodeName == "A") {
                      e.target.parentElement.parentElement.parentElement.classList.remove(
                        "open"
                      );
                      return;
                    }
                    if (e.target.classList.contains("selector__body")) return;
                    e.target.parentElement.parentElement.classList.remove(
                      "open"
                    );

                    if (!e.target.nextSibling) return;
                    e.target.parentElement.previousSibling.querySelector(
                      "p"
                    ).innerHTML = e.target.innerHTML;
                  }}
                  className="selector__body"
                >
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#CC3332" />
                      <path
                        d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                        fill="white"
                      />
                    </svg>
                    {balance.RUB.toFixed(2).toLocaleString("en-US")}
                  </div>
  
                
                </div>
              </div>
                <p>{t('wallet_stats')}</p>
                <div className="selector white-selector  ">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.target.parentElement.classList.toggle("open");
                  }}
                  className="selector__header"
                >
                  <p className="selector__value">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#CC3332" />
                      <path
                        d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                        fill="white"
                      />
                    </svg>

                    {balance.RUB.toFixed(2).toLocaleString("en-US")}
                  </p>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.25 6.375L8.5 10.625L12.75 6.375"
                      stroke="white"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div
                  onClick={(e) => {
                    if (e.target.nodeName == "A") {
                      e.target.parentElement.parentElement.parentElement.classList.remove(
                        "open"
                      );
                      return;
                    }
                    if (e.target.classList.contains("selector__body")) return;
                    e.target.parentElement.parentElement.classList.remove(
                      "open"
                    );

                    if (!e.target.nextSibling) return;
                    e.target.parentElement.previousSibling.querySelector(
                      "p"
                    ).innerHTML = e.target.innerHTML;
                  }}
                  className="selector__body"
                >
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#CC3332" />
                      <path
                        d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                        fill="white"
                      />
                    </svg>
                    {balance.RUB.toFixed(2).toLocaleString("en-US")}
                  </div>
  
                
                </div>
              </div>
               
         </nav>
         <div className="wallet-stat-block">
<div>
    <p>{t('balance')}</p>
    <h2>{formatNumber(walletBalance)}</h2>
</div>

<div>
    <p>{t('deposit_sum')}</p>
    <h2>{formatNumber(depositSum)}</h2>
</div>
<div>
    <p>{t('withdraw_sum')}</p>
    <h2>{formatNumber(withdrawalSum)}</h2>
</div>

<div>
    <p>{t('blocked_by_bonuses')}</p>
    <h2>{formatNumber(earnedReferral)}</h2>
</div>
{wager != null && !isWager &&
<div>
<p>Оставшийся отыгрыш</p>
<h2>{formatNumber(wager)}</h2>
</div>}

                </div>
            </div>
        </div>
        </div>
        
        </>)
}